const language = {
  english: 'English',
  vietnamese: 'Tiếng Việt',

  wallet: 'Ví',
  native_balance_56: 'Số dư BNB',
  native_balance_42161: 'Số dư ETH',
  lz_balance: 'Số dư LZ',
  view_on_56: 'Xem trên BscScan',
  view_on_42161: 'Xem trên ArbiScan',
  referral_link: 'Link giới thiệu',
  your_address: 'Địa chỉ',
  disconnect_wallet: 'Ngắt kết nối',
  connect_wallet: 'Kết nối ví'
}

export default language
