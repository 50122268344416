import './style.scss';

export const LzButton = ({
  theme,
  className,
  borderStyle,
  containerStyle,
  style,
  children,
  onClick,
}: {
  theme?: 'auto' | 'dark' | 'light';
  className?: string;
  borderStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  children: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
  const themeClass = {
    dark: 'forced-dark',
    light: 'forced-light',
  }
  return (
    <div
      className={`lz-button-container ${themeClass[theme!] || ''}`}
      onClick={onClick}
      style={{
        ...borderStyle,
        ...containerStyle
      }}
    >
      <div
        className={`lz-button-text ${className || ''}`}
        style={{
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};
