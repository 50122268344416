import { useWindowSize } from '../../hooks/use-window-size';

export const BrowserView = (props: any) => {
  const { width } = useWindowSize();
  if (width && width > 768) {
    return props.children;
  }
  return null;
};

export const MobileView = (props: any) => {
  const { width } = useWindowSize();
  if (width && width <= 768) {
    return props.children;
  }
  return null;
};
