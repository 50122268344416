import React from "react"
import './Loader.scss'
export default (props) => {
  return (
    <div className="bouncing-loader" style={props.style}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

