import React, { useMemo } from 'react';
import './style.scss';
import { shortenAddressString } from '../../utils/helpers';
import { BscLogoIcon } from '../Icons';
import { NETWORK_SUPPORTED } from "../../utils/constant";
import { ONEDEX_CONFIG, DEFAULT_CHAIN_ID } from '../../utils/constant'
export const NetworkInfoButton = (
  props: React.HTMLAttributes<HTMLDivElement> & { chainId: number },
) => {
  const networkInfo = useMemo(() => {
    let network = NETWORK_SUPPORTED.find((e) => e.chainId === props.chainId)
    if (!network) {
      return NETWORK_SUPPORTED.find((e) => e.chainId === Number(DEFAULT_CHAIN_ID))
    }
    return network
  }, [props.chainId])

  return (
    <div className='network-info-button' {...props}>
      <img src={'/icons/network/' + networkInfo?.logo} alt="" height={24} />
      {/* <div className='network-info-button__brief'>
        <div className='network-info-button__address'>
          {networkInfo?.name}
        </div>
      </div> */}
    </div>
  );
};
