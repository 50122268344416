import en from '../utils/lang/en'
import vi from '../utils/lang/vi'
import {useEffect, useState} from "react";

const sourceString = {
  en,
  vi
}

export const useLanguage = () => {
  const [language, setLanguage] = useState<any>(localStorage.getItem('language') || 'en')

  useEffect(() => {
    localStorage.setItem('language', language)
  }, [language])

  const getText = (key: string) => {
    return sourceString[language][key] ? sourceString[language][key] : key
  }

  const supportedLanguages = {
    en: getText('english'),
    // vi: getText('vietnamese'),
  }

  return {
    language,
    setLanguage,
    getText,
    supportedLanguages
  }
}