import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Web3ReactProvider} from '@web3-react/core';
import {ethers} from 'ethers';
import {AppLayout} from './components/AppLayout';
import {BrowserRouter as Router} from 'react-router-dom';
import './styles/main.scss';
import './index.css';
console.log('TEST_DEPLOY', '1.0')
function getLibrary(provider: any) {
  const library = new ethers.providers.Web3Provider(provider);
  return library;
}


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <AppLayout
          originDapps={[
            {
              Component: React.lazy(() => {
                // @ts-ignore
                import('onedex-interface/dist/component.css');
                return import('onedex-interface/dist/component');
              }),
              configs: require('onedex-interface/dist/configs'),
            },
          ]}
        />
      </Router>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
