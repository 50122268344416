import './style.scss'
import { CopyButton } from "../CopyButton";
import React from "react";

export const CopyableAddress = ({ children }: { children: string }) => {
  return (
    <div className='copyable-address-container'>
      <div className='copyable-address'>
        {children}
      </div>
      <CopyButton
        text={children}
        copiedText='Copied'
      />
    </div>
  );
};
