import React from 'react';
import './style.scss'
import { LinkIcon } from "../Icons";
import { NETWORK_SUPPORTED } from "../../utils/constant";

export const SelectNetworkModal = ({
  visible,
  hideSettingModal,
  setChainIdToDisplay,
  chainIdToDisplay,
}: {
  visible: boolean,
  hideSettingModal: any
  setChainIdToDisplay: any
  chainIdToDisplay: any
}) => {
  return <div className={`lz-select-network__box ${visible ? 'active' : ''}`}>
    <div className={`lz-select-network ${visible ? 'active' : ''}`}>
      <div className='lz-select-network__title'>Select a network</div>
      <div className='lz-select-network__content'>
        {
          NETWORK_SUPPORTED.map((network) => {
            return <div
              className={`lz-select-network__network ${network.chainId === chainIdToDisplay ? 'active' : ''}`}
              onClick={() => {
                setChainIdToDisplay(network.chainId)}
              }
            >
              <div className='lz-select-network__network--top'>
                <div className='lz-select-network__network--top-left'>
                  <span className='lz-select-network__network--logo'>
                    <img src={'/icons/network/' + network.logo} alt=""/>
                  </span>
                  <span className='lz-select-network__network--name'>{network.name}</span>
                </div>
                {
                  network.chainId === chainIdToDisplay &&
                  <span className='lz-select-network__network--selected' />
                }
              </div>
              {
                chainIdToDisplay === network.chainId ?
                  <a
                    className='lz-select-network__network--explorer-link'
                    href={network.explorer}
                    target='_blank'
                  >
                    <span>{network.fullname}</span>
                    <LinkIcon />
                  </a>
                  : ''
              }
            </div>
          })
        }
      </div>
    </div>
  </div>
}
