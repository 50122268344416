import React from 'react';
import './style.scss';
import ReactImageFallback from 'react-image-fallback'
import { getResourceUrl } from "../../utils/helpers";
import { AiOutlineCopy, AiOutlineLeft, AiFillSetting } from 'react-icons/ai'
import { BsChevronLeft } from 'react-icons/bs'
import { TiLightbulb } from 'react-icons/ti'
import { IconType } from 'react-icons'
import { MdNightlight, MdOutlineLightbulb } from 'react-icons/md'
import { useConfigOfBranch } from '../../hooks';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { HiMenu, HiX } from 'react-icons/hi'

const BaseIcon = (props: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span role='img' {...props} className={`lz-icon icon ${props.className || ''}`}>
      {props.children}
    </span>
  );
};
export const BaseGradienIcon = (props: { children: any, size: number, className?: string, onClick?: any }) => {
  return <svg onClick={props.onClick} className={`default-basegradient-icon ${props.className || ''}`} width={props.size} height={props.size} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <linearGradient id="gradient">
      <stop offset="0%" />
      <stop offset="100%" />
    </linearGradient>
    {props.children}
  </svg>
}

type SubIconProps = React.HTMLAttributes<HTMLSpanElement> & {
  containerProps?: React.HTMLAttributes<HTMLSpanElement>;
  url?: string
};

type CountryIconProps = React.HTMLAttributes<HTMLSpanElement> & {
  country: string;
  containerProps?: React.HTMLAttributes<HTMLSpanElement>;
};

export const HomeIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/home.svg' alt='' {...props} />
  </BaseIcon>
);

export const SfarmIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/sfarm.svg' alt='' {...props} />
  </BaseIcon>
);

export const PoolIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/pool.svg' alt='' {...props} />
  </BaseIcon>
);

export const FarmIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/farm.svg' alt='' {...props} />
  </BaseIcon>
);

export const PadIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/pad.svg' alt='' {...props} />
  </BaseIcon>
);

export const VaultIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/vault.svg' alt='' {...props} />
  </BaseIcon>
);

export const BankIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/bank.svg' alt='' {...props} />
  </BaseIcon>
);

export const SwapIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/swap.svg' alt='' {...props} />
  </BaseIcon>
);

export const LzLogoIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    {
      props.url ? <img
        src={props.url}
        width="80%"
        {...props}
      /> : null
    }
  </BaseIcon>
);

export const LzLogoInMobileIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    {
      props.url ? <img
        src={props.url}
        width="80%"
        {...props}
      /> : null
    }
    {/* <ReactImageFallback
      src={props.url}
      fallbackImage={'/icons/logo.svg'}
      alt=''
      {...props}
    /> */}
  </BaseIcon>
);

export const ThemeIcon = (props: any & { type: 'light' | 'dark' }) => {
  if (props.type === 'light') {
    return (
      <BaseIcon>
        <BaseGradienIcon {...props} size={24}>
          <MdOutlineLightbulb size={24} />
        </BaseGradienIcon>

        {/* <img src={'/icons/theme.svg'} alt='' {...props} /> */}
      </BaseIcon>
    );
  }
  return (
    <BaseIcon>
      <BaseGradienIcon {...props} size={24}>
        <MdNightlight size={24} />
      </BaseGradienIcon>
      {/* <img src={'/icons/dark-theme.svg'} alt='' {...props} /> */}
    </BaseIcon>
  );
};

export const TelegramIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/telegram.svg' alt='' {...props} />
  </BaseIcon>
);

export const TwitterIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/twitter.svg' alt='' {...props} />
  </BaseIcon>
);

export const LanguageIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/language.svg' alt='' {...props} />
  </BaseIcon>
);

export const VerticalLaunchZoneIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <ReactImageFallback
      src={getResourceUrl('images/vertical-icon.svg')}
      fallbackImage={getResourceUrl('images/vertical-icon.png')}
      alt=''
      width={16}
      //{...props}
    />
  </BaseIcon>
);

export const CheckedIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src={'/icons/checked.svg'} alt='' {...props} />
  </BaseIcon>
);


export const ZoomInIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src={'/icons/zoom-in.svg'} alt='' {...props} />
  </BaseIcon>
);

export const ZoomOutIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/zoom-out.svg' alt='' {...props} />
  </BaseIcon>
);

export const MenuIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={24}>
      <HiMenu size={24} />
    </BaseGradienIcon>
  </BaseIcon>
);

export const BscLogoIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/bsc-logo.svg' alt='' {...props} />
  </BaseIcon>
);

export const ErrorIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/error-icon.svg' alt='' width={24} {...props} />
  </BaseIcon>
);

// @ts-ignore
export const CopyIcon = (props: any) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={18}>
      <AiOutlineCopy size={18} />
    </BaseGradienIcon>
  </BaseIcon>
);

export const ArrowUpIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={24}>
      <MdOutlineKeyboardArrowUp size={24} />
    </BaseGradienIcon>
  </BaseIcon>
);

export const XIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={24}>
      <HiX size={24} />
    </BaseGradienIcon>
  </BaseIcon>
);

export const DoubleRightArrowIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/double-right-arrow.svg' alt='' {...props} />
  </BaseIcon>
);

export const DoubleLeftArrowIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img
      src='/icons/double-right-arrow.svg'
      alt=''
      {...props}
      style={{ transform: 'scale(-1, -1)', ...props?.style }}
    />
  </BaseIcon>
);

export const FacebookLogoIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/facebook-logo.svg' alt='' {...props} />
  </BaseIcon>
);

export const YoutubeLogoIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/youtube-logo.svg' alt='' {...props} />
  </BaseIcon>
);

export const SettingIcon = (props: any) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={24}>
      <AiFillSetting />
    </BaseGradienIcon>
  </BaseIcon>
);

export const GlobalCommunityLogoIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps} className='global-community-logo'>
    <img src='/icons/global-community-logo.svg' alt='' {...props} />
  </BaseIcon>
);

export const LeftArrowIcon = (props: any) => (
  <BaseIcon {...props.containerProps}>
    <BaseGradienIcon {...props} size={18}>
      <AiOutlineLeft />
    </BaseGradienIcon>
  </BaseIcon>
);

export const LeftArrowGreyIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/left-arrow-grey.svg' alt='' {...props} />
  </BaseIcon>
);

export const DownArrowGreyIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/down-arrow-grey.svg' alt='' {...props} />
  </BaseIcon>
);

export const BlackRightArrowIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/black-right-arrow.svg' alt='' {...props} />
  </BaseIcon>
);

export const OpenInNewTabIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src={'icons/open-in-new-tab.svg'} alt='' {...props} />
  </BaseIcon>
);


// download flag svg image in https://github.com/yammadev/flag-icons
export const CountryFlagIcon = (props: CountryIconProps) => {
  return (
    <BaseIcon {...props.containerProps}>
      <img src={`/icons/countries/${props.country.toLowerCase()}.svg`} alt='' {...props} />
    </BaseIcon>
  )
}
export const EarnIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/open-in-new-tab.svg' alt='' {...props} />
  </BaseIcon>
);

export const DocsIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/docs.svg' alt='' {...props} />
  </BaseIcon>
);

export const HelpIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/help.svg' alt='' {...props} />
  </BaseIcon>
);

export const LinkIcon = (props: SubIconProps) => (
  <BaseIcon {...props.containerProps}>
    <img src='/icons/link-icon.svg' alt='' {...props} />
  </BaseIcon>
);
