import { InjectedConnector } from '@web3-react/injected-connector'
// import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
// import { AuthereumConnector } from '@web3-react/authereum-connector'
// import { TorusConnector } from '@web3-react/torus-connector'
export const RPC_URLS: { [chainId: number]: string } = {
    10: 'https://optimism-mainnet.public.blastapi.io/' as string,
}

let supportedChainIds = process.env.REACT_APP_IS_TESTING ? [10] : [10]


const injected = {
    connector: new InjectedConnector({
        supportedChainIds
    }),
    image: '/images/metamask.svg',
    title: 'Metamask',
    desc: 'Connect to your Metamask Wallet'
}
// const network = {
//     connector: new NetworkConnector({
//         urls: { 10: RPC_URLS[10] },
//         defaultChainId: 10
//     }),
//     image: '/images/walletconnect.svg',
//     title: 'WalletConnect',
//     desc: 'Scan with WalletConnect to connect'
// }
const walletconnect = {
    connector: new WalletConnectConnector({
        rpc: { 
            10: RPC_URLS[10], 
         },
        qrcode: true,
        chainId: 10
    }),
    image: '/images/walletconnect.svg',
    title: 'WalletConnect',
    desc: 'Scan with WalletConnect to connect'
}
// const walletlink = {
//     connector: new WalletLinkConnector({
//         url: RPC_URLS[10],
//         appName: 'web3-react example',
//         supportedChainIds: [10]
//     }),
//     image: '/images/coinbase.svg',
//     title: 'Coinbase',
//     desc: 'Connect to your Coinbase Wallet'
// }
// const authereum = {
//     connector: new AuthereumConnector({ chainId: 10 }),
//     image: '/images/authereum.svg',
//     title: 'Authereum',
//     desc: 'Connect with your Authereum account'
// }

// const torus = {
//     connector: new TorusConnector({ chainId: 10 }),
//     image: '/images/torus.svg',
//     title: 'Torus',
//     desc: 'Connect with your Torus account'
// }

export default {
    injected,
    // torus,
    // network,
    // authereum,
    // walletlink,
    walletconnect
};
