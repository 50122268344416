import React, { useEffect, useState } from 'react';
import './style.scss'
import { CountryFlagIcon, LanguageIcon } from "../Icons";
import { useLanguage } from "../../hooks/use-language";
import { LzText } from "../LzText";
import { useWindowSize } from "../../hooks/use-window-size";
import { useLocation } from "react-router-dom";

export const SettingModal = ({
  visible,
  hideSettingModal,
  resetLayout
}: {
  visible: boolean,
  hideSettingModal: any
  resetLayout: any
}) => {
  const location = useLocation();
  const { width } = useWindowSize()
  return <div className={`lz-setting-box ${visible ? 'active' : ''}`}>
    {
      visible && <div className='overlay' onClick={hideSettingModal}/>
    }
    <div className={`lz-setting ${visible ? 'active' : ''}`}>
      <div className='lz-setting__head'>
        <LzText style={{ fontSize: 24 }}>Setting</LzText>
      </div>
      {
        location.pathname.includes('future') &&
        width &&
        width > 1207 &&
        (
          <div className='lz-setting__box'>
            <LzText style={{ fontSize: 16 }} className='lz-setting__box--title'>Future</LzText>
            <div className='lz-setting__row'>
              <span className='lz-setting__row'>Layout</span>
                <span
                  className='lz-setting__future--reset-layout'
                  onClick={() => {
                    localStorage.removeItem('futures-layouts')
                    resetLayout()
                  }}
                >
                <svg
                  width="16" height="16" viewBox="0 0 12 12" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9C7.19599 9 8.22952 8.30016 8.71155 7.28545C8.83004 7.03603 9.1283 6.92988 9.37773 7.04837C9.62716 7.16686 9.73331 7.46512 9.61482 7.71455C8.97339 9.06478 7.59641 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C7.19519 2 8.26738 2.5242 9 3.3543V3C9 2.72386 9.22386 2.5 9.5 2.5C9.77614 2.5 10 2.72386 10 3V4.5C10 5.05228 9.55228 5.5 9 5.5H7.75C7.47386 5.5 7.25 5.27614 7.25 5C7.25 4.72386 7.47386 4.5 7.75 4.5H8.59877C8.07955 3.6025 7.10971 3 6 3Z"
                          fill="#FCBF06"
                    />
                </svg>
                <span>Reset</span>
              </span>
            </div>
          </div>
        )}

      <div className='lz-setting__box'>
        <LzText style={{ fontSize: 16 }} className='lz-setting__box--title'>General</LzText>
        <div className='lz-setting__row'>
          <span className='lz-setting__row'>Language</span>
          <SelectLanguage/>
        </div>
      </div>
    </div>
  </div>
}

const SelectLanguage = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const { language, supportedLanguages, setLanguage } = useLanguage()

  return <div className='language'>
    <div
      className='language--btn'
      onClick={() => {
        setVisible(!visible)
      }}
    >
      <LanguageIcon className='footer__language-icon'/>
      <span className='footer__language-text'>{language.toUpperCase()}</span>
    </div>
    <div className={`language__select-box ${visible ? 'active' : ''}`}>
      {
        Object.entries(supportedLanguages).map((lang, key) => {
          return (
            <div
              onClick={() => {
                setLanguage(lang[0])
              }}
              key={key}
              className={`language__select-option ${language === lang[0] ? 'selected' : ''}`}
            >
              <CountryFlagIcon country={lang[0]}/>
              <span className='ml-1'>{lang[1]}</span>
            </div>
          )
        })
      }
    </div>
  </div>
}
