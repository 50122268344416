import { CopyIcon } from '../Icons';
import './style.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState } from "react";

export const CopyButton = ({ text, copiedText }: { text: string, copiedText: string }) => {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if(visible) {
      setTimeout(() => {
        if(visible) {
          setVisible(false)
        }
      }, 1000)
    }
  }, [visible])

  return (
    <div className='copy-button'>
      <div className={`copy-button__copied-notify ${visible && 'active'}`}>{copiedText}</div>
      <CopyToClipboard
        onCopy={() => {
          setVisible(true)
        }}
        text={text}
      >
        <CopyIcon containerProps={{className: 'copyable-address-copy-icon'}}/>
      </CopyToClipboard>
    </div>
  )
};
