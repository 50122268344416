import conf from "onedex-interface/dist/configs"
export const LS_CONNECTOR = 'web3connector';
export const LS_THEME = 'theme';
export const DEFAULT_APP_PATH = '/';
export const THEME_SUPPORTED = ['light', 'dark']
export const ONEDEX_CONFIG = conf
export const DEFAULT_CHAIN_ID = conf.otherParams.DEFAULT_CHAIN_ID;
export const NETWORK_SUPPORTED = process.env.REACT_APP_IS_TESTING ? [
  {
    chainId: 10,
    name: 'Optimism',
    fullname: 'Optimism Chain',
    logo: '10.svg',
    explorer: "https://optimistic.etherscan.io/",
    nativeTokenSymbol: 'ETH'
  },
  {
    chainId: 1337,
    name: 'Local',
    fullname: 'Local Chain',
    logo: '1337.svg',
    explorer: "#",
    nativeTokenSymbol: 'ETH'
  },
] :
  [
    {
      chainId: 10,
      name: 'Optimism',
      fullname: 'Optimism Chain',
      logo: '10.svg',
      explorer: "https://optimistic.etherscan.io/",
      nativeTokenSymbol: 'ETH'
    }
  ]
