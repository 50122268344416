import React, { Suspense, useCallback, useRef } from 'react';
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import './style.scss';
import '../../styles/main.scss';
import {
  LeftArrowIcon,
  LzLogoIcon,
  ThemeIcon,
  // SettingIcon,
  BaseGradienIcon
} from '../Icons';
import 'web3-react-modal/dist/index.css';
import { Web3ReactModal } from 'web3-react-modal';
import connectors from '../../utils/connectors';
import { getResourceUrl, weiToNumber } from '../../utils/helpers';
import { Link, useLocation, matchPath, useHistory } from 'react-router-dom';
import { LzButton } from '../LzButtons';
import { AccountInfoButton } from '../AccountInfoButton';
import { LzText } from '../LzText';
import { CopyableAddress } from '../CopyableAddress';
import LzAbi from '../../assets/abis/erc20.json';
import { ethers } from 'ethers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserView } from '../DeviceDetect';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLanguage } from "../../hooks/use-language";
import GitInfo from 'react-git-info/macro';
import { TermOfUsePopup } from "../TermOfUsePopup";
import { CopyButton } from "../CopyButton";
import { SettingModal } from "../SettingModal";
import { DappType, HelpLinkType, MenuLinkType, SidebarConfigType } from "../../utils/types";
import { DEFAULT_APP_PATH, LS_CONNECTOR, LS_THEME, THEME_SUPPORTED } from "../../utils/constant";
import { MobileLayout } from "../Layout/MobileLayout";
import { NetworkInfoButton } from "../NetworkInfoButton";
import { NETWORK_SUPPORTED } from "../../utils/constant"
import { SelectNetworkModal } from "../SelectNetworkModal";
import { Loading } from "../Loading";
import { switchNetwork, useChainId } from '../../utils/chain';
import Loader from '../Loading2/Loader';

import { BsFillGridFill } from 'react-icons/bs'
import { IoHomeSharp } from 'react-icons/io5'

const CrossStorageClient = require('cross-storage').CrossStorageClient

function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
} const convert = (rgb: string) => {
  try {
    let ret = {
      r: rgb.split(",")[0].substring(5),
      g: rgb.split(",")[1],
      b: rgb.split(",")[2],
    }
    return ret
  } catch (error) {
    return null
  }
}
function getRgba(primaryColor: string) {
  try {
    let ret = null
    let color = primaryColor + ''
    if (color.match(/^#(?:[A-Fa-f0-9]{3}){1,2}$/gm)) {
      // hex
      ret = hexToRGB(color, 0.4)
    }
    if (color.match(/^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/gm)) {
      let tmp = convert(color)
      ret = tmp ? `rgba(${tmp.r},${tmp.g},${tmp.b},0.4)` : null
    }
    return ret
  } catch (error) {
    return null
  }
}

export const AppLayout = (props: { originDapps: DappType[] }) => {
  const { activate, account, deactivate, library } = useWeb3React();
  const [chainId] = useChainId()
  const refTimeoutConfig = useRef<any>(null)
  const [dapps, setDapps] = useState<DappType[]>(props.originDapps)
  const [ready, setReady] = useState<any>(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>();
  const [bnbBalance, setBnbBalance] = useState<any>();
  const [lzBalance, setLzBalance] = useState<any>();
  const [visibleWalletModal, setVisibleWalletModal] = useState<any>();
  const [theme, setTheme] = useState<'dark' | 'light'>('dark');
  const [isWidget, seIsWidget] = useState<boolean>(false);
  const [isFullChart, setIsFullChart] = useState<boolean>(false);
  const location = useLocation();
  const [showDesktopWalletExpand, setShowDesktopWalletExpand] = useState<boolean>(false);
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const { language, getText } = useLanguage()
  const refNetworkBtn = useRef(null);
  const refAccountBtn = useRef(null);
  // const refSettingBtn = useRef(null);
  const [xStorageClient, setXStorageClient] = useState<typeof CrossStorageClient>(undefined)
  const [componentKey, setComponentKey] = useState<number>(0)
  // const [helpLinks, setHelpLinks] = useState<HelpLinkType[]>([])
  const [menuLinks] = useState<MenuLinkType[]>([])
  const [siteName, setSiteName] = useState<string>("")
  // const [chainIdToDisplay, setChainIdToDisplay] = useState<number>(
  //   Number(new URLSearchParams(location.search).get('chainId')) ||
  //   (Number(localStorage.getItem('chainId')) ||
  //     56)
  // )
  const [chainIdToDisplay, setChainIdToDisplay] = useState<number>(chainId as any)
  const [visibleSelectNetworkModal, setVisibleNetworkModal] = useState<boolean>(false)
  const network = NETWORK_SUPPORTED.find((n) => n.chainId === chainId)
  console.log('chainIdToDisplay !== chainId', chainIdToDisplay, chainId)
  const makeOverideVariable = (primary: any, gradien_1: any, gradien_2: any, primary_text_btn_color: any, config: any) => {
    if (!primary || !gradien_1 || !gradien_2) return
    // Set the value of variable --blue to another value (in this case "lightblue")
    var r: any = document.querySelector(':root');
    if (!r) return
    r.style.setProperty('--onedex-color-primary', primary);
    let primaryAlpha = null

    primaryAlpha = getRgba(primary)
    primaryAlpha && r.style.setProperty('--onedex-color-primary-opt', primaryAlpha);

    r.style.setProperty('--onedex-color-primary-linear', `linear-gradient(180deg, ${gradien_1} 0%,  ${gradien_2} 100%)`);
    r.style.setProperty('--onedex-color-primary-light', gradien_1);
    r.style.setProperty('--onedex-color-primary-light-1', gradien_2);
    r.style.setProperty('--onedex-color-text-btn-primary', primary_text_btn_color);
    refTimeoutConfig.current && clearTimeout(refTimeoutConfig.current)
    refTimeoutConfig.current = setTimeout(() => {
      setReady(config)
    }, 1000);
  }
  const handleFetchConfig = useCallback(async (chainId: any) => {
    let { fetchBranch, fetchConfig } = dapps[0].configs
    let { gradien_1, primary, gradien_2, primary_text_btn_color } = {
      gradien_1: null,
      primary: null,
      gradien_2: null,
      primary_text_btn_color: null
    }
    try {
      let branchId = await fetchBranch(chainId);
      let config = await fetchConfig(Number(branchId))
      let { config: color } = config.find((c: any) => c.chainId == chainId)
      primary = color.primary
      gradien_1 = color.gradien_1
      gradien_2 = color.gradien_2
      primary_text_btn_color = color.primary_text_btn_color
      primary_text_btn_color && primary && gradien_1 && gradien_2 && makeOverideVariable(primary, gradien_1, gradien_2, primary_text_btn_color, color)
    } catch (error) {
      setReady(true)
      console.warn('--------FETCH CONFIG ERROR', error)
    }
  }, [dapps])
  useEffect(() => {
    setChainIdToDisplay(chainId)
  }, [chainId])

  useEffect(() => {
    if (dapps?.length) {
      let { fetchBranch, fetchConfig } = dapps[0].configs
      if (fetchBranch && fetchConfig) {
        handleFetchConfig(chainId)
      }
    }
  }, [chainId])
  useEffect(() => {
    const linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");

    // Hardcode use theme of lz
    linkElement.setAttribute(
      "href",
      "https://onedexdev.github.io/onedexTheme.css"
    );
    document.head.appendChild(linkElement);
  }, [])

  useEffect(() => {
    const fetConfig = async () => {
      const res: SidebarConfigType = require('../../configs/sidebar-config.json')
      const newDapps = dapps.filter((dapp) => {
        return res.disabledApps.includes(dapp.configs.name.toLowerCase()) ? false : true
      })
      setDapps(newDapps)
      // setMenuLinks(res.menuLinks)
      // setHelpLinks(res.helpLinks)
      setSiteName(res.siteName || "OneDex")
    }
    fetConfig()
  }, [])

  useEffect(() => {
    for (let i in dapps) {
      // if child menu is match with path
      const children = dapps[i].configs.children;
      for (let j in children) {
        if (matchPath(location.pathname, { path: children[j].path, exact: children[j].path === '/' })) {
          document.title = (siteName ? siteName + ' | ' : '') + children[j].name
          return
        }
      }

      if (matchPath(location.pathname, { path: dapps[i].configs.path })) {
        document.title = (siteName ? siteName + ' | ' : '') + (dapps[i].configs.name === 'Home' ? 'The Ultimate DeFi Platform' : dapps[i].configs.name)
      }
    }

  }, [location.pathname, siteName]);

  useHotkeys('esc', (e) => {
    e.preventDefault();
    setShowDesktopWalletExpand(false);
    setVisibleNetworkModal(false);
  });

  useEffect(() => {
    if (!process.env.REACT_APP_X_STORAGE_URL) {
      return
    }
    const storage = new CrossStorageClient(process.env.REACT_APP_X_STORAGE_URL)
    storage
      .onConnect()
      .then(() => {
        setXStorageClient(storage)
      })
      .then(() => {
        console.log('x-storage', 'connected', process.env.REACT_APP_X_STORAGE_URL)
        const refAddress = (new URLSearchParams(location.search)).get('r')
        if (refAddress) {
          const formalizedAddress = ethers.utils.getAddress(refAddress)
          return storage.set('LZ_REFERRAL', formalizedAddress)
            .then(() => console.log('x-storage', 'LZ_REFERRAL', formalizedAddress))
        }
      })
      .catch(console.error)
  }, [process.env.REACT_APP_X_STORAGE_URL])

  useEffect(() => {
    const urlSearch = new URLSearchParams(location.search)
    setIsFullChart(urlSearch.get('fullchart') !== null)
    seIsWidget(urlSearch.get('widget') !== null)
    const initTheme = 'dark';
    setTheme('dark')

    const gitInfo = GitInfo();
    console.log('Environment:', process.env.REACT_APP_NODE_ENV)
    console.log('Hash:', gitInfo.commit.hash)
  }, []);

  useEffect(() => {
    const initConnector = localStorage.getItem(LS_CONNECTOR);
    if (initConnector) {
      const connector = Object.values(connectors)
        .map(({ connector }) => connector)
        .find((connector) => connector?.constructor?.name === initConnector);
      if (connector) {
        activate(connector);
      }
    }
  }, [activate]);

  useEffect(() => {
    if (!!account && !!library) {
      library
        .getBalance(account)
        .then((balance: any) => {
          setBnbBalance(weiToNumber(balance));
        })
        .catch(() => {
          setBnbBalance(null);
        });
      setBnbBalance(undefined);
    }
  }, [account, library, chainId]);

  useEffect(() => {
    const menuItem = findSelectedMenuItem();
    if (!selectedMenuItem || !menuItem.configs.parent || menuItem.configs.parent !== selectedMenuItem.configs.parent) {
      setSelectedMenuItem(menuItem)
    }
  }, [location.pathname])

  const handleChainId = (id: number) => {
    localStorage.setItem('chainId', id.toString())
    setVisibleNetworkModal(false)
    setChainIdToDisplay(id)
  }

  const findSelectedMenuItem = () => {
    if (location.pathname === '/') {
      return {
        Component: React.lazy(() => {
          // @ts-ignore
          import('onedex-interface/dist/component.css');
          return import('onedex-interface/dist/component');
        }),
        configs: require('onedex-interface/dist/configs'),
      }
    }

    for (let i in dapps) {
      const children = dapps[i].configs.children;
      if (children) {
        for (let j in children) {
          if (matchPath(location.pathname, { path: children[j].path, exact: children[j].path === '/' })) {
            console.log(dapps[i].Component)
            return {
              Component: dapps[i].Component,
              configs: children[j]
            }
          }
        }
      } else if (matchPath(location.pathname, { path: dapps[i].configs.path, exact: dapps[i].configs.path === '/' })) {
        return dapps[i];
      }
    }

    return dapps[0];
  }

  const isMenuExpand = ({ configs }: any) => {
    if (matchPath(location.pathname, { path: configs.path, exact: configs.path === '/' })) {
      return true;
    } else if (configs.children) {
      for (let child of configs.children) {
        if (matchPath(location.pathname, { path: child.path, exact: child.path === '/' })) {
          return true;
        }
      }
    }
    return false;
  }

  const Component = selectedMenuItem?.Component;
  console.log('chainIdToDisplay !== chainId', chainIdToDisplay, chainId)
  let getSideBarLogo = (config: { logo: string, socials: string[], sidebarLogo: string }) => {
    if (config?.sidebarLogo) {
      console.log('logo sidebarLogo', config?.sidebarLogo)
      return config?.sidebarLogo
    }
    console.log('logo default', config?.logo)
    return config?.logo
  }

  if (!ready) {
    return (
      <div className={`${theme ? theme : 'light'}`}>
        {
          true && <div className='loading-config' style={{
            position: 'absolute',

          }}>
            <Loader />
          </div>
        }
      </div>
    )
  }

  return (
    <>
      <div className={`${theme ? theme : 'light'}`}>
        {/* {
          !isFullChart && !isWidget &&
          <TermOfUsePopup theme={theme} />
        } */}
        <div className='body'>
          <BrowserView>
            {
              !isFullChart && !isWidget && (
                <div className='sidebar'>
                  <div className='sidebar__top'>
                    <Link to={DEFAULT_APP_PATH} className='sidebar__lz-logo-container'>
                      <LzLogoIcon url={getSideBarLogo(ready)} className='text-align branch-logo' containerProps={{ className: 'text-center' }} />
                    </Link>
                    <div className='sidebar__menu-item-list-container'>
                      <div className='sidebar__menu-item-list'>
                        {dapps?.map(({ configs: menuItem }) => {
                          const children = menuItem.children
                          return children?.map(subMenuItem => {
                            console.info('subMenuItem', {
                              name: subMenuItem.name
                            })
                            const Icon = subMenuItem.icon;
                            return <div className='sidebar__menu-item'>
                              <Link to={subMenuItem.menuLink || subMenuItem.path}>
                                <div
                                  className={`sidebar__menu-item__label ${isMenuExpand({ configs: subMenuItem }) && 'active'}`}>

                                  {!!menuItem.icon ? <BaseGradienIcon className={`sidebar__menu-item__icon ${matchPath(location.pathname, {
                                    path: subMenuItem.path,
                                    exact: subMenuItem.path === '/',
                                  }) ? 'active' : ''}`} size={16}>
                                    <Icon className='sidebar__menu-item__icon_svg' />
                                  </BaseGradienIcon> : null}
                                  <div className='sidebar__menu-item__title '>
                                    <span className='sidebar__menu-item__title-text'>
                                      {subMenuItem.name}
                                    </span>
                                  </div>
                                  <div
                                    className={`sidebar__menu-item__title-selected ${matchPath(location.pathname, {
                                      path: subMenuItem.path,
                                      exact: subMenuItem.path === '/',
                                    }) ? 'active' : ''}`}
                                  />
                                </div>
                              </Link>
                            </div>
                          })
                        })}

                      </div>
                    </div>

                    <div className='sidebar__network-and-connect-btns'>

                      <OutsideClickHandler
                        disabled={!visibleSelectNetworkModal}
                        onOutsideClick={(e: any) => {
                          setVisibleNetworkModal(false)
                        }}
                      >
                        <NetworkInfoButton
                          //@ts-ignore
                          ref={refNetworkBtn}
                          //@ts-ignore
                          chainId={chainIdToDisplay}
                          onClick={() => setVisibleNetworkModal(!visibleSelectNetworkModal)}
                        />
                        <SelectNetworkModal
                          hideSettingModal={() => {
                            setVisibleNetworkModal(false)
                          }}
                          visible={visibleSelectNetworkModal}
                          setChainIdToDisplay={handleChainId}
                          chainIdToDisplay={chainId}
                        />
                      </OutsideClickHandler>

                      {!location.pathname.includes('futures') && (
                        <OutsideClickHandler
                          disabled={!showDesktopWalletExpand}
                          onOutsideClick={(e: any) => {
                            // if (!e.path.includes(refAccountBtn.current)) {
                            setShowDesktopWalletExpand(false)
                            // }
                          }}
                        >
                          <div
                            ref={refAccountBtn}
                            className='sidebar__account-info-button-container'
                            onClick={() => {
                              console.log('123')
                              account &&
                                setShowDesktopWalletExpand(
                                  (showDesktopWalletExpand) => !showDesktopWalletExpand,
                                );
                              !account && setVisibleWalletModal(true);
                            }}
                          >
                            {!account ? (
                              <LzButton theme='dark' className='sidebar__connect-wallet-button'>
                                {getText('connect_wallet')}
                              </LzButton>
                            ) : null}
                            {account ? <AccountInfoButton
                              account={account}
                              balance={bnbBalance}
                              isWrongNetwork={chainIdToDisplay !== chainId}
                            /> : null
                            }
                          </div>
                          <div className={`sidebar__expand ${showDesktopWalletExpand ? 'show' : ''}`}>
                            <LeftArrowIcon
                              className='sidebar__expand__left-arrow-icon'
                              onClick={() => {
                                setShowDesktopWalletExpand(
                                  (showDesktopWalletExpand) => !showDesktopWalletExpand,
                                );
                              }}
                            />

                            <div className='sidebar__expand--head'>
                              <LzText>{getText('your_address')}</LzText>
                              {/* <LzText className='sidebar__expand__referral'>
                                <span>{getText('referral_link')}</span>
                                <CopyButton
                                  text={`${window.location.origin.toString()}${window.location.pathname}?r=${account}`}
                                  copiedText='Copied'
                                />
                              </LzText> */}
                            </div>
                            <CopyableAddress>{account!}</CopyableAddress>
                            <div className='sidebar__expand__bnb-balance'>
                              <div>{getText('native_balance_' + chainId)}</div>
                              <div>{bnbBalance}</div>
                            </div>
                            {chainId == 56 &&
                              <div className='sidebar__expand__lz-balance'>
                                <div>{getText('lz_balance')}</div>
                                <div>{lzBalance}</div>
                              </div>
                            }
                            <LzText className='sidebar__expand__view-on-bsc-scan'>
                              <a target='_blank' href={`${network?.explorer}address/${account}`}>
                                {getText('view_on_' + chainId)}
                              </a>
                            </LzText>
                            <LzButton
                              onClick={() => {
                                deactivate();
                                localStorage.removeItem(LS_CONNECTOR);
                                setShowDesktopWalletExpand(false);
                              }}
                            >
                              {getText('disconnect_wallet')}
                            </LzButton>
                          </div>
                        </OutsideClickHandler>

                      )}
                      <div>

                      </div>

                    </div>
                  </div>
                  <div className='sidebar__bottom'>
                    <div className='sidebar__bottom__container'>
                      <div className='sidebar__bottom__configs'>
                        {/* <ThemeIcon
                          className='sidebar__bottom__theme-icon'
                          onClick={() => {
                            const themeToSet = theme === 'dark' ? 'light' : 'dark';
                            localStorage.setItem(LS_THEME, themeToSet);
                            setTheme(themeToSet);
                          }}
                          type={theme}
                        /> */}

                        {/* <div
                          ref={refSettingBtn}
                          className='sidebar__bottom__language'
                          onClick={() => {
                            setShowSettingModal(!showSettingModal)
                          }}>
                          <SettingIcon className='sidebar__bottom__setting-icon' />
                        </div> */}
                      </div>
                      {/* <div className='sidebar__bottom__social-media-icons'>
                        {
                          helpLinks.map((item: any) => {
                            return (
                              <a href={'#'} >
                                <img src={'/icons/' + item.icon} alt="" />
                              </a>
                            )
                          })
                        }
                      </div> */}
                    </div>
                  </div>
                </div>
              )
            }

            <SettingModal
              visible={showSettingModal}
              hideSettingModal={(e: any) => {
                setShowSettingModal(false)
              }}
              resetLayout={() => {
                setComponentKey(Math.random())
              }}
            />

            <div className={`desktop-content ${isWidget || isFullChart ? 'fullscreen' : ''}`}>
              {Component && (
                <Suspense fallback={
                  <Loader style={{
                    marginTop: '-8.2rem'
                  }} />
                }>
                  <Component
                    key={componentKey}
                    chainId={chainIdToDisplay}
                    useHistory={useHistory}
                    useLocation={useLocation}
                    language={language}
                    theme={theme}
                    useWeb3React={useWeb3React}
                    xStorageClient={xStorageClient}
                    useSubPage={() => location.pathname}
                    env={process.env.REACT_APP_NODE_ENV || 'production'}
                    showConnectWalletModal={() => setVisibleWalletModal(true)}
                  />
                </Suspense>
              )}
            </div>

          </BrowserView>
          <MobileLayout
            sideBarLogoUrl={getSideBarLogo(ready)}
            chainIdToDisplay={chainIdToDisplay}
            setChainIdToDisplay={handleChainId}
            isWidget={isWidget}
            isFullChart={isFullChart}
            selectedMenuItem={selectedMenuItem}
            dapps={dapps}
            theme={theme}
            setTheme={setTheme}
            bnbBalance={bnbBalance}
            lzBalance={lzBalance}
            menuLinks={menuLinks}
            // helpLinks={helpLinks}
            setVisibleWalletModal={setVisibleWalletModal}
          >
            {Component && (
              <Suspense fallback={null}>
                <Component
                  theme={theme}
                  useHistory={useHistory}
                  useLocation={useLocation}
                  language={language}
                  useWeb3React={useWeb3React}
                  useSubPage={() => location.pathname}
                  xStorageClient={xStorageClient}
                  env={process.env.REACT_APP_NODE_ENV || 'production'}
                  showConnectWalletModal={() => setVisibleWalletModal(true)}
                  chainId={chainIdToDisplay}
                />
              </Suspense>
            )}
          </MobileLayout>
          <Web3ReactModal
            visible={visibleWalletModal}
            setVisible={setVisibleWalletModal}
            providerOptions={connectors}
            onConnect={(connector: any, _: any, email: string) => {
              const name = connector?.constructor?.name;
              if (email && name === 'MagicConnector') {
                connector.email = email
              }
              activate(connector, async (err: any) => {
                await switchNetwork(10)
              });
              if (name) {
                localStorage.setItem(LS_CONNECTOR, name);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
