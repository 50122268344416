import { useWeb3React } from '@web3-react/core';
import { DEFAULT_CHAIN_ID, NETWORK_SUPPORTED } from '../utils/constant'
import { RPC_URLS } from './connectors';
export const NETWORK_METADATA = {
    [10]: {
        chainId: "0x" + Number(10).toString(16),
        chainName: "Optimistic",
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: RPC_URLS[10],
        blockExplorerUrls: ['https://optimistic.etherscan.io'],
    },
};
export const useChainId = () => {
    let { chainId } = useWeb3React();

    if (chainId == null || NETWORK_SUPPORTED.find(({ chainId: pChainId }) => pChainId === chainId) == null) {
        console.log('chainIdToDisplay', DEFAULT_CHAIN_ID, NETWORK_SUPPORTED.find(({ chainId: pChainId }) => pChainId === chainId, chainId))
        return [DEFAULT_CHAIN_ID]
    }
    console.log('chainIdToDisplay current', DEFAULT_CHAIN_ID, NETWORK_SUPPORTED.find(({ chainId: pChainId }) => pChainId === chainId, chainId))
    return [chainId]
}
export const addNetwork = async (metadata: any) => {
    await window.ethereum.request({ method: "wallet_addEthereumChain", params: [metadata] }).catch();
};
export const switchNetwork = async (chainId: number) => {
    try {
        const chainIdHex = "0x" + chainId.toString(16);
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainIdHex }],
        });
    } catch (ex: any) {
        // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
        // This error code indicates that the chain has not been added to MetaMask.
        // 4001 error means user has denied the request
        // If the error code is not 4001, then we need to add the network
        if (ex.code !== 4001) {
            return await addNetwork(NETWORK_METADATA[chainId]);
        }

        console.error("error", ex);
    }
};