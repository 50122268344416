import React, {useEffect, useRef} from 'react';
import './style.scss';
import { shortenAddressString } from '../../utils/helpers';
import { ErrorIcon } from '../Icons';
import { useWeb3React } from "@web3-react/core";
import { NETWORK_SUPPORTED } from "../../utils/constant";
var jazzicon = require('@metamask/jazzicon')

export const AccountInfoButton = (
  props: React.HTMLAttributes<HTMLDivElement> & {
    account: string
    balance: number
    isWrongNetwork: boolean
  },
) => {
  const myRef = useRef<any>(null)
  const {chainId} = useWeb3React()

  const network = NETWORK_SUPPORTED.find((n) => n.chainId === chainId)
  useEffect(() => {
    if(myRef && myRef?.current && props.account) {
      const addr = props.account.slice(2, 10);
      const seed = parseInt(addr, 16);
      myRef?.current?.replaceChildren(jazzicon(25, seed))
    }
  }, [props.isWrongNetwork, myRef, props.account])
  return (
    <div className='account-info-button' {...props}>
      {
        props.isWrongNetwork
          ?
          <div className='account-info-button__network-error' key={1}>
            <ErrorIcon containerProps={{ className: 'account-info-button__network-error--icon' }} />
            <div className='account-info-button__network-error--tool-tip'>
              Your wallet is connected to a different network.
            </div>
          </div>
          :
          <div className="11" ref={myRef} key={2}/>
        // <img src={'/icons/network/' + network?.logo} alt='' height={24} />
      }
      <div className='account-info-button__brief'>
        <div className='account-info-button__address'>
          {props.account && shortenAddressString(props.account)}
        </div>
        {/* <div className='account-info-button__bnb-balance'>{props.balance} {network?.nativeTokenSymbol}</div> */}
      </div>
    </div>
  );
};
