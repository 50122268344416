import React, { useRef } from "react";
import {
  ArrowUpIcon,
  BaseGradienIcon,
  LzLogoInMobileIcon,
  MenuIcon
} from "../../Icons";
import { Link, matchPath, useLocation } from "react-router-dom";
import { IParallax } from "@react-spring/parallax";
import _ from "lodash";
import { DappType, MenuLinkType } from "../../../utils/types";
import { DEFAULT_APP_PATH } from "../../../utils/constant";

import './style.scss'

export const MobileHeader = ({
  isWidget,
  isFullChart,
  dapps,
  hide,
  showMobileMenu,
  menuLinks,
  setShowMobileMenu,
  sideBarLogoUrl
}: {
  isWidget: boolean
  isFullChart: boolean
  selectedMenuItem: DappType
  dapps: DappType[]
  hide: boolean
  showMobileMenu: boolean
  setShowMobileMenu: any
  menuLinks: MenuLinkType[],
  sideBarLogoUrl?: string
}) => {
  const parallax = useRef<IParallax>(null);
  const location = useLocation()
  return (
    <div className={`mobile-header ${hide && 'hide'}`}>
      {
        !isFullChart && !isWidget && (
          <div className={`navbar ${showMobileMenu && 'active'}`}>
            <div className='navbar__top-line'>
              <Link to={DEFAULT_APP_PATH}>
                <div className='navbar__lz-logo-icon-container'>
                  <img src={sideBarLogoUrl} style={{ height: '24px', aspectRatio: '4/1' }} />
                </div>
              </Link>
              {showMobileMenu ? (
                <div className='navbar__menu-icon'>
                  <ArrowUpIcon
                    onClick={() => {
                      setShowMobileMenu(false);
                    }}
                  />
                </div>
              ) : (
                <MenuIcon
                  className='navbar__menu-icon'
                  onClick={() => {
                    setShowMobileMenu(true);
                  }}
                />
              )}
            </div>
            <div className={`navbar__menu-item-list ${showMobileMenu && 'active'}`}>
              {dapps?.map(({ configs: menuItem }) => {
                const Icon = menuItem.icon;

                if (menuItem.children && menuItem.children.length > 0) {
                  return menuItem.children.map((child: any) => {
                    if (child.name === 'DeBank') {
                      return null
                    }
                    const Icon = child.icon;
                    const isMenuSelected = matchPath(location.pathname, {
                      path: child.path,
                      exact: child.path === '/',
                    })
                    return (
                      <Link to={child.menuLink || child.path} className={`navbar__menu-item ${isMenuSelected ? 'active' : ''}`}>
                        <BaseGradienIcon size={16} className={`navbar__menu-item__icon ${isMenuSelected ? 'active' : ''}`}>
                          <Icon />
                        </BaseGradienIcon>
                        <div className='navbar__menu-item__title'>
                          <span className={`navbar__menu-item__title-text ${isMenuSelected ? 'active' : ''}`}>{child.name}</span>
                        </div>
                      </Link>
                    )
                  })
                }
                return (
                  <Link to={menuItem.menuLink || menuItem.path} className='navbar__menu-item'>
                    {!!menuItem.icon ? <Icon className='navbar__menu-item__icon' /> : null}
                    <div className='navbar__menu-item__title'>
                      <span className='navbar__menu-item__title-text'>{menuItem.name}</span>
                      {matchPath(location.pathname, {
                        path: menuItem.path,
                        exact: menuItem.path === '/',
                      }) ? (
                        <div className='navbar__menu-item__title-selected' />
                      ) : null}
                    </div>
                  </Link>
                );
              })}
              {
                menuLinks.map((item) => {
                  return <a className='navbar__menu-item' href={item.link} target={item.target}>
                    <img src={'/icons/' + item.icon} alt="" width={15} height={15} />
                    <div className='navbar__menu-item__title'>
                      <span className='navbar__menu-item__title-text'>
                        {item.name}
                      </span>
                    </div>
                  </a>
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}
