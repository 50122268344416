const language = {
  english: 'English',
  vietnamese: 'Tiếng Việt',

  wallet: 'Wallet',
  native_balance_56: 'BNB Balance',
  native_balance_42161: 'ETH Balance',
  native_balance_1337: 'ETH Balance',
  native_balance_10: 'ETH Balance',
  lz_balance: 'LZ Balance',
  referral_link: 'Referral link',
  view_on_56: 'View on BscScan',
  view_on_42161: 'View on ArbiScan',
  view_on_1337: 'View on Local',
  view_on_10: 'View on OptimisticScan',
  your_address: 'Your address',
  disconnect_wallet: 'Disconnect Wallet',
  connect_wallet: 'Connect Wallet'
}

export default language
