import { LzText } from "../../LzText";
import { CopyButton } from "../../CopyButton";
import { CopyableAddress } from "../../CopyableAddress";
import {
  // DownArrowGreyIcon,
  // LanguageIcon,
  MenuIcon,
  ThemeIcon,
  XIcon
} from "../../Icons";
import { LzButton } from "../../LzButtons";
import OutsideClickHandler from "react-outside-click-handler";
import React, { useRef, useState } from "react";
import { AccountInfoButton } from "../../AccountInfoButton";
import { useWeb3React } from "@web3-react/core";
import { useLanguage } from "../../../hooks/use-language";
import { useHistory, useLocation } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import './style.scss'
import { HelpLinkType } from "../../../utils/types";
import { CurrentNetworkIcon } from "../../CurrentNetworkIcon";
import { SelectNetworkModal } from "../../SelectNetworkModal";
import { NETWORK_SUPPORTED } from "../../../utils/constant"

const LS_CONNECTOR = 'web3connector';
const LS_THEME = 'theme';

export const MobileFooter = ({
  chainIdToDisplay,
  setChainIdToDisplay,
  theme,
  setTheme,
  bnbBalance,
  lzBalance,
  hide,
  // helpLinks,
  setVisibleWalletModal
}: {
  theme: any,
  setTheme: any,
  bnbBalance: number,
  lzBalance: any,
  helpLinks?: HelpLinkType[]
  hide: any,
  chainIdToDisplay: number
  setChainIdToDisplay: any
  setVisibleWalletModal: any
}) => {
  const location = useLocation();
  const { account, deactivate, chainId } = useWeb3React()
  const { getText,
    // language,
    // supportedLanguages,
    // setLanguage
  } = useLanguage()
  const refMobileAccountBtn = useRef(null);
  const refNetworkBtn1 = useRef(null);
  const [showMobileFooterExpand, setShowMobileFooterExpand] = useState<boolean>(false);
  const [visibleSelectNetworkModal, setVisibleNetworkModal] = useState<boolean>(false)
  // const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const network = NETWORK_SUPPORTED.find((n) => n.chainId === chainId)
  useHotkeys('esc', (e) => {
    e.preventDefault();
    setShowMobileFooterExpand(false);
  });

  return (
    <div className={`footer ${hide && 'hide'}`}
      style={{
        justifyContent: location.pathname.includes('futures') ? 'end' : 'center',
        // position: location.pathname.includes('futures') ? 'inherit' : 'fixed',
        paddingLeft: 16,
        paddingRight: 16
      }}
    >
      {location.pathname.includes('futures') ? '' : account ? (
        <OutsideClickHandler
          disabled={!showMobileFooterExpand}
          onOutsideClick={(e: any) => {
            setShowMobileFooterExpand(false);
          }}
        >
          <div
            ref={refMobileAccountBtn}
          >
            <AccountInfoButton
              onClick={() => {
                setShowMobileFooterExpand(!showMobileFooterExpand);
              }}
              account={account}
              balance={bnbBalance}
              isWrongNetwork={chainIdToDisplay !== chainId}
            />
          </div>
          <div className={`footer__expand ${showMobileFooterExpand ? 'show' : ''}`}>
            <div className='footer__expand--head'>
              <LzText>{getText('your_address')}</LzText>
              {/* <LzText className='footer__expand__referral'>
                <span>{getText('referral_link')}</span>
                <CopyButton
                  text={`${window.location.origin.toString()}${window.location.pathname}?r=${account}`}
                  copiedText='Copied'
                />
              </LzText> */}
            </div>
            <CopyableAddress>{account!}</CopyableAddress>
            <div className='footer__expand__bnb-balance'>
              <div>{getText('native_balance_' + chainId)}</div>
              <div>{bnbBalance}</div>
            </div>
            {chainId == 56 &&
              <div className='footer__expand__lz-balance'>
                <div>{getText('lz_balance')}</div>
                <div>{lzBalance}</div>
              </div>
            }
            <div className='footer__expand__view-on-bsc-scan'>
              <LzText className='footer__expand__view-on-bsc-scan-link'>
                <a target='_blank' href={`${network?.explorer}address/${account}`}>
                  {getText('view_on_' + chainId)}
                </a>
              </LzText>
              {/* <div className='footer__expand__social-medias'>
                {
                  helpLinks.map((item) => {
                    return <a href={item.href} target='_blank'>
                      <img src={'/icons/' + item.icon} alt="" />
                    </a>
                  })
                }
              </div> */}
            </div>
            {chainIdToDisplay !== chainId &&
              <div className='footer__error-newtwork-container'>
                <a className='footer__error-network'>
                  Your wallet is connected to a different network.
                </a>
              </div>
            }
            <LzButton
              containerStyle={{
                flex: 1
              }}
              onClick={() => {
                deactivate();
                localStorage.removeItem(LS_CONNECTOR);
                setShowMobileFooterExpand(false);
              }}
            >
              {getText('disconnect_wallet')}
            </LzButton>
          </div>
        </OutsideClickHandler>

      ) : (
        <LzButton
          containerStyle={{
            flex: 1
          }}
          theme='dark'
          className='footer__connect-wallet-button'
          onClick={() => {
            setVisibleWalletModal(true);
          }}
        >
          {getText('connect_wallet')}
        </LzButton>
      )}

      <OutsideClickHandler
        disabled={!visibleSelectNetworkModal}
        onOutsideClick={(e: any) => {
          console.log(1)
          setVisibleNetworkModal(false)
        }}
      >
        <div
          ref={refNetworkBtn1}
          onClick={() => {
            console.log(2)
            setVisibleNetworkModal(!visibleSelectNetworkModal)
          }}
        >
          <CurrentNetworkIcon
            // @ts-ignore
            chainId={chainIdToDisplay}
          />
        </div>
        <SelectNetworkModal
          hideSettingModal={() => {
            setVisibleNetworkModal(false)
          }}
          visible={visibleSelectNetworkModal}
          setChainIdToDisplay={setChainIdToDisplay}
          // @ts-ignore
          chainIdToDisplay={chainIdToDisplay}
        />
      </OutsideClickHandler>

      {/* <OutsideClickHandler
        onOutsideClick={() => {
          setShowSettingModal(false);
        }}
      >
        <div className='footer__language' onClick={() => setShowSettingModal(!showSettingModal)}>
          <LanguageIcon className='footer__language-icon' />
          <span className='footer__language-text'>{language.toUpperCase()}</span>
          <div className={`footer__language--select ${showSettingModal ? 'active' : ''}`}>
            <span className='btn-close-language'>
              <DownArrowGreyIcon />
            </span>
            {
              Object.entries(supportedLanguages).map((lang, key) => {
                return (
                  <div
                    onClick={() => {
                      setLanguage(lang[0])
                    }
                    }
                    key={key}
                    className={`footer__language--select-option ${language === lang[0] ? 'selected' : ''}`}
                  >
                    {lang[1]}
                  </div>
                )
              })
            }
          </div>
        </div>
      </OutsideClickHandler> */}

      {/* <ThemeIcon
        type={theme}
        onClick={() => {
          const themeToSet = theme === 'dark' ? 'light' : 'dark';
          localStorage.setItem(LS_THEME, themeToSet);
          setTheme(themeToSet);
        }}
      /> */}

      {!location.pathname.includes('futures') && (
        <div className='footer__setting-btn'>
          {showMobileFooterExpand ? (
            <XIcon
              onClick={() => {
                setShowMobileFooterExpand((showMobileFooter) => !showMobileFooter);
              }}
            />
          ) : (
            <MenuIcon
              onClick={() => {
                account && setShowMobileFooterExpand((showMobileFooter) => !showMobileFooter);
                !account && setVisibleWalletModal(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
