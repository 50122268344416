import React, { useMemo } from 'react';
import './style.scss';
import { NETWORK_SUPPORTED } from "../../utils/constant";

export const CurrentNetworkIcon = (
  props: React.HTMLAttributes<HTMLDivElement> & { chainId: number },
) => {
  const networkInfo = useMemo(() => {
    return NETWORK_SUPPORTED.find((e) => e.chainId === props.chainId)
  }, [props.chainId])

  return (
    <div className='network-info-button-mobile' {...props}>
      <img src={'/icons/network/' + networkInfo?.logo} alt="" height={24} />
      <div className='network-info-button__brief'>
      </div>
    </div>
  );
};
