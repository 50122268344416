import { ethers, utils } from 'ethers';
import configs from 'onedex-interface/dist/configs'
import BranchStorageAbi from '../assets/abis/BranchStorage.json'
import { contractFetcher } from './contractFetcher'

export const shortenAddressString = (address: string) => {
    return address.slice?.(0, 6) + '...' + address.slice?.(address.length - 4, address.length)
}

export const weiToNumber = (wei: string, decimal: number = 18) => {
    let number = utils.formatUnits(wei, decimal)
    let arr = number.split('.')
    if (arr.length > 0) {
        arr[1] = arr[1].slice(0, 4)
    }
    number = arr.join('.')
    return number.slice(-2) === '.0' ? number.slice(0, -2) : number
}

export const getResourceUrl = (name: string) => {
    return process.env.REACT_APP_WHITE_LABEL_CONFIG_URL + '/sites/' + window.location.hostname + '/' + name
}

export function importImage(name: string) {
    let tokenImage = null;
    try {
        tokenImage = require("images/" + name);
    } catch (error) {
        console.error(error);
    }
    return tokenImage;
}
export function getBranchId() {
    try {
        const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
        // @ts-ignore
        var numbers = subdomain?.match(/\d+/g).map(Number);
        if (numbers.length) {
            return numbers[0]
        }
        return 2 // Default branch 0
    } catch (e) {
        console.warn("Not found branchID fallback to branch 0")
        return 2
    }
}
export const getTopicConfigs = () => ethers.utils.formatBytes32String('configs')

export async function getConfig() {
    const branchId = getBranchId();
    const { DEFAULT_CHAIN_ID, CHAINS } = configs.otherParams
    const { BranchStorage } = CHAINS[DEFAULT_CHAIN_ID]['PLATFORM_CONTRACTS']
    let topic = getTopicConfigs()
    let contractCall = contractFetcher(undefined as any, BranchStorageAbi, [])
    return contractCall(`branchStorageAddress:branchToppicVal(${branchId})${DEFAULT_CHAIN_ID}`, DEFAULT_CHAIN_ID, BranchStorage, "branchToppicVal", branchId, topic)
}


export const decodeConfig = configs.decodeConfig

