import { MobileHeader } from "../MobileHeader";
import React, { useState } from "react";
import { MobileFooter } from "../MobileFooter";
import { MobileView } from "../../DeviceDetect";
import { DappType, HelpLinkType, MenuLinkType } from "../../../utils/types";
import { useLocation } from "react-router-dom";
import './style.scss'
import { VerticalLaunchZoneIcon, ZoomInIcon, ZoomOutIcon } from "../../Icons";
import { useWindowSize } from "../../../hooks/use-window-size";

export const MobileLayout = ({
  setChainIdToDisplay,
  chainIdToDisplay,
  isWidget,
  isFullChart,
  selectedMenuItem,
  dapps,
  children,
  theme,
  setTheme,
  bnbBalance,
  lzBalance,
  // helpLinks,
  menuLinks,
  setVisibleWalletModal,
  sideBarLogoUrl
}: {
  sideBarLogoUrl?: string
  setChainIdToDisplay: any
  chainIdToDisplay: number
  isWidget: boolean
  isFullChart: boolean
  selectedMenuItem: DappType
  dapps: DappType[]
  children: any
  theme: string
  setTheme: any
  bnbBalance: number
  lzBalance: string
  // helpLinks: HelpLinkType[],
  menuLinks: MenuLinkType[],
  setVisibleWalletModal: any
}) => {
  const location = useLocation()
  const isFuture = location.pathname.includes('futures')
  const [isHideMenu, setHideMenu] = useState<boolean>(isFuture)
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { height } = useWindowSize()

  const showMenu = () => {
    setHideMenu(false)
  }
  const hideMenu = () => {
    setTimeout(() => {
      setShowMobileMenu(false)
      setHideMenu(true)
    }, 100)
  }

  return (
    <MobileView>
      <div
        className='mobile-layout'
        style={{ height: isFuture ? height : '100vh' }}
      >
        <MobileHeader
          sideBarLogoUrl={sideBarLogoUrl}
          isWidget={isWidget}
          isFullChart={isFullChart}
          selectedMenuItem={selectedMenuItem}
          dapps={dapps}
          hide={isHideMenu}
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
          menuLinks={menuLinks}
        />
        <div className={`mobile-layout__content ${isWidget ? 'widget' : ''} ${isFullChart ? 'fullchart' : ''}`}>
          {children}
          {
            isFuture && !isHideMenu && (
              <div
                className='mobile-layout__overlay'
                onClick={hideMenu}
                onTouchStart={hideMenu}
              />
            )
          }
        </div>
        {isHideMenu && isFuture ?
          (<div
            className='mobile-layout__show-lz-menu'
            onClick={showMenu}
          >
            <ZoomInIcon />
            <VerticalLaunchZoneIcon />
          </div>) :
          null
        }
        <MobileFooter
          chainIdToDisplay={chainIdToDisplay}
          setChainIdToDisplay={setChainIdToDisplay}
          theme={theme}
          setTheme={setTheme}
          bnbBalance={bnbBalance}
          lzBalance={lzBalance}
          hide={isHideMenu}
          helpLinks={[]}
          setVisibleWalletModal={setVisibleWalletModal}
        />
      </div>
    </MobileView>
  )
}
